import React, { Component } from "react"
import HeaderComponent from "../components/header.component"
import LayoutComponent from "../components/layout.component"
import { navigate } from "gatsby"

const axios = require("axios")

class SignUpComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repassword: "",
      customError: null,
      errors: null,
      touched: {
        company: false,
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        repassword: false,
      },
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleBlur(field, evt) {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleClick(e) {
    const errors = this.validate()

    if (Object.values(errors).some(el => el)) {
      const touched = this.state.touched
      Object.keys(touched).forEach(el => {
        touched[el] = true
      })
      this.setState({
        touched: touched,
        errors: errors,
      })
      return
    }

    this.setState({
      errors: null,
      customError: null,
    })

    let serverUrl = process.env.API_URL + "user"
    axios
      .post(serverUrl + "/", {
        company: this.state.company,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
      })
      .then(response => {
        const accountId = response.data.accountId
        const loginToken = response.data.loginToken
        this.goToInviteTeams(accountId, loginToken)
      })
      .catch(error => {
        console.log("catch", error)
        if (error.response) {
          this.setState({
            errors: error.response.data,
          })
        } else {
          this.setState({
            customError:
              "An error occured while sending the request: " + error.message,
          })
        }
      })
  }

  isBlank(str) {
    return !str || /^\s*$/.test(str)
  }

  validatePassword(pwd) {
    return /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,20}$/.test(
      pwd
    )
  }

  validate() {
    const {
      company,
      firstName,
      lastName,
      email,
      password,
      repassword,
    } = this.state

    return {
      company: this.isBlank(company) || company.length > 50,
      firstName: this.isBlank(firstName) || firstName.length > 50,
      lastName: this.isBlank(lastName) || lastName.length > 50,
      email: this.isBlank(email) || email.length > 70,
      password:
        this.isBlank(password) ||
        !this.validatePassword(password) ||
        password.length > 50,
      repassword:
        this.isBlank(repassword) ||
        password !== repassword ||
        repassword.length > 50,
    }
  }

  goToInviteTeams(accountId, loginToken) {
    sessionStorage.setItem(
      "wdx_signup",
      JSON.stringify({
        accountId: accountId,
        loginToken: loginToken,
      })
    )

    navigate("invite-teams", {
      state: {
        accountId: accountId,
        loginToken: loginToken,
      },
    })
    /*
    navigate("select-carriers",
    {
      state: { accountId: accountId,
               loginToken: loginToken }
    });
    */
  }

  render() {
    let errors = this.validate()
    if (this.state.errors) {
      errors = this.state.errors
    }

    const renderError = text => {
      return <span className="ml-4 text-red">{text}</span>
    }

    const shouldMarkError = field => {
      const hasError = errors[field]
      const shouldShow = this.state.touched[field]

      return hasError ? shouldShow : false
    }

    return (
      <LayoutComponent>
        {/*FIXME:*/}
        {/*<SeoComponent title="Sign Up"/>*/}
        <div className="container">
          <HeaderComponent theme="light" showMenu="false" />
        </div>
        <section className="container my-5 text-secondary">
          <div className="text-center mb-5">
            <h2 className="text-primary mb-3">Create New Account</h2>
            <h5>
              Already have an account?
              <a href="https://app.gowdx.com/login.aspx"> Sign-in</a>
            </h5>
          </div>
          <form id="sign-up-form" className="mx-auto mb-5 sign-up-form">
            <div className="form-group">
              <label htmlFor="company">Company</label>
              {errors.lastName ? renderError(errors.company) : ""}
              <input
                className={
                  shouldMarkError("company")
                    ? "form-control error"
                    : "form-control"
                }
                type="text"
                maxlength="50"
                id="company"
                name="company"
                value={this.state.company}
                onChange={this.handleInputChange}
                onBlur={() => this.handleBlur("company")}
              />
            </div>
            <div className="d-flex mx-n2 flex-wrap">
              <div className="flex-grow-1 px-2">
                <div className="form-group">
                  <label htmlFor="first-name">First Name</label>
                  {errors.lastName ? renderError(errors.firstName) : ""}
                  <input
                    className={
                      shouldMarkError("firstName")
                        ? "form-control error"
                        : "form-control"
                    }
                    type="text"
                    maxlength="50"
                    id="first-name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    onBlur={() => this.handleBlur("firstName")}
                  />
                </div>
              </div>
              <div className="flex-grow-1 px-2">
                <div className="form-group">
                  <label htmlFor="last-name">Last Name</label>
                  {errors.lastName ? renderError(errors.lastName) : ""}
                  <input
                    className={
                      shouldMarkError("lastName")
                        ? "form-control error"
                        : "form-control"
                    }
                    type="text"
                    maxlength="50"
                    id="last-name"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    onBlur={() => this.handleBlur("lastName")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              {errors.email ? renderError(errors.email) : ""}
              <input
                type="email"
                className={
                  shouldMarkError("email")
                    ? "form-control error"
                    : "form-control"
                }
                id="email"
                maxlength="70"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                onBlur={() => this.handleBlur("email")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              {errors.password ? renderError(errors.password) : ""}
              <input
                type="password"
                className={
                  shouldMarkError("password")
                    ? "form-control error"
                    : "form-control"
                }
                id="password"
                maxlength="50"
                name="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                onBlur={() => this.handleBlur("password")}
              />
              <div>
                <small
                  id="emailHelp"
                  className="text-secondary font-weight-light d-inline-block font-12 align-text-top"
                >
                  Password must be at least 8 characters (20 max) and must
                  include 1 uppercase letter, 1 lowercase letter, and 1 number.
                </small>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="re-password">Re-enter password</label>
              {errors.repassword ? renderError(errors.repassword) : ""}
              <input
                type="password"
                className={
                  shouldMarkError("repassword")
                    ? "form-control error"
                    : "form-control"
                }
                id="re-password"
                maxlength="50"
                name="repassword"
                value={this.state.repassword}
                onChange={this.handleInputChange}
                onBlur={() => this.handleBlur("repassword")}
              />
            </div>
          </form>
          <div className="text-center mb-100">
            {this.state.customError && (
              <div className="my-2 text-red">{this.state.customError}</div>
            )}
            <button
              type="button"
              className="btn btn-info mb-4"
              onClick={this.handleClick}
            >
              Create Account
            </button>
          </div>
        </section>
      </LayoutComponent>
    )
  }
}

export default SignUpComponent
